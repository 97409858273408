import "./App.css";
import logo from "./assets/tezotopia-logo.png";
import Button from "./Button/Button";
import { generatePayloadBytes } from "./generatePayloadToSign";
import { SigningType } from "@airgap/beacon-sdk";
import { b58cencode } from "@taquito/utils";
import { getActiveAccount, wallet, clearActiveAccount } from "./tezos";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const backendURL = process.env.REACT_APP_BACKEND_URL;
const queryParams = new URLSearchParams(window.location.search);

function App() {
	return (
		<div className="App">
			<img src={logo} alt="logo" />
			<br />
			<div className="steps">
				<h2>What To Expect</h2>
				<ol>
					<li>Connect your Wallet</li>
					<li>Sign the message to verify identity</li>
				</ol>
				<Button onClick={verifyUser}>Verify wallet</Button>
			</div>
			<ToastContainer position="bottom-right" />
		</div>
	);
}

function postToServer(signedPayload, payload, publicKey) {
	const discordId = queryParams.get("discord");
	const url = backendURL;
	const data = {
		publicKey,
		signedPayload,
		payload,
		discordId,
	};
	axios
		.post(url, data)
		.then(res => {
			const { success, message } = res.data;
			if (success===false) {
				toast.error(`Oops,  ${message}`);
			} else {
				toast.success(`Success! ${message}`);
			}
		})
		.catch(err => {
			console.log(err);
			toast.error(`Something went wrong, ERR: ${err}`);
		});
}

/**
 * Get hex for the message of payload
 * @param  message Mesage that will be signed by the user
 * @returns hex of message
 */

async function verifyUser() {
	if (!queryParams.get("discord")) {
		toast.error("Generate Link from Discord");
		return;
	}
	const account = await getActiveAccount();
	const payloadBytes = generatePayloadBytes(
		account.address,
		queryParams.get("discordUsername")
	);
	const payload = {
		signingType: SigningType.MICHELINE,
		payload: payloadBytes,
		sourceAddress: account.address,
	};
	const signedData = await wallet.client.requestSignPayload(payload);
	let { publicKey } = await wallet.client.getActiveAccount();
	// Airgap wallet gives hex encoded public key
	// we need to convert it into base58 encoded public key (edpk...)
	if (publicKey.length > 55) {
		publicKey = b58cencode(publicKey, new Uint8Array([13, 15, 37, 217]));
	}
	postToServer(signedData.signature, payloadBytes, publicKey);
	await clearActiveAccount();
}

export default App;
