/**
 * Get hex for the message of payload
 * @param  message Mesage that will be signed by the user
 * @returns hex of message
 */
export function getMessageHex(message) {
	const input = Buffer.from(message);
	const prefix = Buffer.from("0501", "hex");
	const len_bytes = Buffer.from(
		message.length.toString(16).padStart(8, "0"),
		"hex"
	);
	const value = Buffer.concat(
		[prefix, len_bytes, input],
		prefix.length + len_bytes.length + input.length
	);
	return value.toString("hex");
}

export function generatePayloadBytes(userAddress, discordUsername) {
	const dappName = "tezotopia.com";
	const ISO8601formatedTimestamp = new Date().toISOString();

	// The full string
	const formattedInput = [
		"Tezos Signed Message: Confirming that Discord account",
		discordUsername,
		"and wallet address",
		userAddress,
		"both belong to me; for Dapp",
		dappName,
		"at",
		ISO8601formatedTimestamp,
	].join(" ");
	const payloadBytes = getMessageHex(formattedInput);

	return payloadBytes;
}
