import "./Button.css";

export default function Button({
	onClick,
	disabled,
	active,
	children,
	size = "normal",
	color = "primary",
	type = "rounded",
	startElement,
	endElement,
	className = "",
	onMouseEnter,
	onMouseOut,
	onMouseLeave,
}) {
	// RENDER
	return (
		<button
			className={`button ${type} ${size} ${color} ${
				active ? "active" : ""
			} ${className}`}
			onClick={onClick}
			disabled={disabled}
			onMouseEnter={onMouseEnter}
			onMouseOut={onMouseOut}
			onMouseLeave={onMouseLeave}>
			{startElement && <span>{startElement}</span>}
			<span>{children}</span>
			{endElement && <span>{endElement}</span>}
		</button>
	);
}
