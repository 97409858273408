import { BeaconWallet } from "@taquito/beacon-wallet";

const DAPP_NAME = "Tezotpia Discord Verification Bot";
const RPC_URL = "https://tcinfra.net/rpc/mainnet";
const NETWORK = "mainnet";

const wallet = new BeaconWallet({
	name: DAPP_NAME,
	preferredNetwork: NETWORK,
	colorMode: "light",
});

// Setting the wallet as the wallet provider for Taquito.

const network = {
	type: NETWORK,
	rpcUrl: RPC_URL,
};

const getActiveAccount = async () => {
	const activeAccount = await wallet.client.getActiveAccount();

	// no active account, we need permissions first
	if (!activeAccount) {
		await wallet.requestPermissions({ network });
		return getActiveAccount();
	}

	return activeAccount;
};

const clearActiveAccount = async () => {
	return wallet.client.clearActiveAccount();
};

export { wallet, getActiveAccount, clearActiveAccount };
